
import { Component, Vue } from 'nuxt-property-decorator'
import ReviewItem from '../ReviewItem.vue'
import { STUB_REVIEWS } from '@/stubs/reviews'

@Component({
  components: {
    ReviewItem
  }
})
export default class extends Vue {
  reviews: any[] = []

  async fetch () {
    await this.fetchReviews()
  }

  async fetchReviews () {
    try {
      if (process.server) {
        this.reviews = STUB_REVIEWS
        return
      }
      const { data: reviews } = await this.$axios.$get(process.env.API_ENDPOINT + 'comments', {
        // @ts-ignore
        requireToken: false,
        cache: true,
        params: {
          client_app_id: process.env.APP_ID,
          page: 0,
          page_size: 4
        }
      })

      this.reviews = reviews
    } catch (err) {
      this.reviews = STUB_REVIEWS
      this.$sentry.captureException(err)
    }
  }
}
