import { render, staticRenderFns } from "./ReviewItem.vue?vue&type=template&id=23adbe91&scoped=true"
import script from "./ReviewItem.vue?vue&type=script&lang=ts"
export * from "./ReviewItem.vue?vue&type=script&lang=ts"
import style0 from "./ReviewItem.vue?vue&type=style&index=0&id=23adbe91&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23adbe91",
  null
  
)

export default component.exports