
import { Vue, Prop, Component } from 'nuxt-property-decorator'
import { Review } from '~/types/Review'
@Component({
  name: 'Reviews-item'
})
export default class extends Vue {
  @Prop() review!: Review
  isExpanded = false
}
