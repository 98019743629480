// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/notify/profile-dark.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/img/stars/star-empty.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/img/stars/star-filled.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-blue[data-v-23adbe91]{color:#2a4863!important;font-size:18px;line-height:23.29px}.user_icon[data-v-23adbe91]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");margin-right:10px;max-height:40px;max-width:40px;min-height:40px;min-width:40px;transform:translateY(-5px)}.estimation__star[data-v-23adbe91]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.mod-filled[data-v-23adbe91]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.reviews__text[data-v-23adbe91]{color:#2a4863!important;font-size:16px;line-height:20.7px}.right-arrow[data-v-23adbe91]{height:7px;width:5px}span[data-v-23adbe91]{color:#3684dc;cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
